import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from 'react'
import Slider from "react-slick";

const Clients = () => {

    
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
            className={className}
            style={{ ...style, marginRight:20, display: "block", backgroundColor:'#ff4f48',color:'#ff4f48',borderRadius:10,zIndex:5 }}
            onClick={onClick}
            />
            );
        }
        
        function SamplePrevArrow(props) {
            const { className, style, onClick } = props;
            return (
                <div
                className={className}
                style={{ ...style, marginLeft:20, display: "block",backgroundColor:'#ff4f48',color:'#ff4f48',borderRadius:10,zIndex:5 }}
                onClick={onClick}
                />
                );
            }



            var settings = {
                dots: true,
                infinite: false,
                speed: 500,
                slidesToShow: 5,
                slidesToScroll: 3,
                initialSlide: 0,
                nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: true,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            initialSlide: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            initialSlide: 2
                        }
                    }
                ]
            }


            return (
                <section style={{marginTop:30}} class="section contact" id="contact" aria-label="contact">
            <div class="container">

                <h2 class="h2 section-title">Our Clients</h2>

                <p class="section-text">

                </p>

                
                    <Slider {...settings}>
                        <div>
                            <img style={{ height: 80, width: 120 }} src="https://res.cloudinary.com/dyueow7yj/image/upload/v1615557318/resize-16155572312147197398casumologo_ehgd0f.png" />
                        </div>

                        <div>
                            <img style={{ height: 80, width: 120 }} src="https://res.cloudinary.com/dyueow7yj/image/upload/v1591621048/Leovegas_vracrz.png" />
                        </div>

                        <div>
                            <img style={{ height: 80, width: 120 }} src="https://res.cloudinary.com/dyueow7yj/image/upload/v1592965721/luckydayscolorcashpakar_i6loct.png" />
                        </div>

                        <div>
                            <img style={{ height: 80, width: 120 }} src="https://res.cloudinary.com/dyueow7yj/image/upload/v1594037474/lotto247_dfofgh.png" />
                        </div>

                        <div>
                            <img style={{ height: 80, width: 120 }} src="https://res.cloudinary.com/dyueow7yj/image/upload/v1594471291/resize-159447127018901077121996_cdxrrj.png" />
                        </div>

                        <div>
                            <img style={{ height: 80, width: 120 }} src="https://res.cloudinary.com/dyueow7yj/image/upload/v1595577476/resize-1595577418191661524422Betlogotype_gdvvid.png" />
                        </div>

                        <div>
                            <img style={{ height: 80, width: 120 }} src="https://res.cloudinary.com/dyueow7yj/image/upload/v1599718001/resize-15997179871864757788download1_znhr9b.jpg" />
                        </div>

                        <div>
                            <img style={{ height: 80, width: 120 }} src="https://res.cloudinary.com/dyueow7yj/image/upload/v1600586739/resize-1600586720277007304rabetfoto_zvmkqe.jpg" />
                        </div>

                        <div>
                            <img style={{ height: 80, width: 120,marginLeft:10 }} src="https://res.cloudinary.com/dyueow7yj/image/upload/v1619058834/imresizer_bhobqw.jpg" />
                        </div>

                        <div>
                            <img  src="https://res.cloudinary.com/dyueow7yj/image/upload/v1644995270/jeetplay-casino-new-logo-ImResizer_bad9cu.jpg" />
                        </div>

                        <div>
                            <img  src="https://res.cloudinary.com/dyueow7yj/image/upload/v1595432540/resize-1595432330219075111700x350_m3kkmz.jpg" />
                        </div>



                    </Slider>
                </div>

           
        </section>
    )
}

export default Clients