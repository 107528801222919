import React from 'react'
import Homemain from '../components/Homemain'
import Aboutus from './Aboutus'
import Clients from './Clients'
import Contact from './Contact'
import Modelss from './Modelss'
import Services from './Services'

const Home = () => {
  return (
    <div>
        <Homemain/>
        <Modelss/>
        <Services/>
        <Aboutus/>
        <Clients/>
        <Contact/>

    </div>
  )
}

export default Home