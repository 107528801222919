import React from 'react'
import Cardview from '../components/Cardview'

const Modelss = () => {
  return (
    <div style={{marginTop:30}}>
    <section class="section service" id="service" aria-label="service">
        <div class="container">

            <h2 class="h2 section-title">Our Working Model</h2>

            <p class="section-text">
                we work on listed models and get result
            </p>


            <div className='grid-list container'>
                <Cardview differ={true}  img={'laptop-outline'} text={'Cost Per Action'} subtext={'That means publishers take all the risk for running ads to get conversion instead of getting clicks or impressions. It is also a widely used model to grow a business.'}/>

                <Cardview differ={true} img={'phone-portrait-outline'} subtext={'The price an advertiser pays for each time their video ad is broadcast is referred to as cost per view (CPV). This pay approach relies'} text={'Cost Per View'}/>

                <Cardview differ={true} img={'megaphone-outline'} subtext={'CPI is one of them, and while the name makes it appear straightforward (pay-per-install means you get paid when someone downloads your app, right'} text={'Cost Per Install'}/>

                <Cardview differ={true} img={'mail-outline'} subtext={'In CPS, statistic varies significantly between digital and physical products sold from one channel to other. Advertisers benefit from this model since it helps'} text={'Cost Per Sale'}/>

                <Cardview differ={true} img={'clipboard-outline'} subtext={'CPL has the ability of the ad to capture new leads or sales. You can get potential customers or contact that comes from email marketing or any'} text={'Cost Per Leads'}/>
                
                <Cardview differ={true} img={'hammer-outline'} subtext={'The ability to attract clicks and attract on the landing page determines the success of that advertising. This type of campaign is typically used to drive visitors'} text={'Cost Per Click'}/>


               
            </div>

        </div>
    </section>
</div>
  )
}

export default Modelss