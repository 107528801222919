import React from 'react'

const Contact = () => {
  return (
    <section style={{marginTop:30}} class="section contact" id="contact" aria-label="contact">
        <div class="container">

          <h2 class="h2 section-title">Let's Contact With Us</h2>

          <p class="section-text">
          
          </p>

          <form  class="contact-form">

            <div class="input-wrapper">
              <input type="text" name="name" aria-label="name" placeholder="Your name*" required class="input-field"/>

              <input type="email" name="email_address" aria-label="email" placeholder="Email address*" required
                class="input-field"/>
            </div>

            <div class="input-wrapper">
              <input type="text" name="subject" aria-label="subject" placeholder="Subject" class="input-field"/>

              <input type="number" name="phone" aria-label="phone" placeholder="Phone number" class="input-field"/>
            </div>

            <textarea name="message" aria-label="message" placeholder="Your message...*" required
              class="input-field"></textarea>

            {/* <div class="checkbox-wrapper">
              <input type="checkbox" name="terms_and_policy" id="terms" required class="checkbox"/>

              <label for="terms" class="label">
                Accept <a href="#" class="label-link">Terms of Services</a> and <a href="#" class="label-link">Privacy
                  Policy</a>
              </label>
            </div> */}

            <button type="submit" class="btn btn-primary">Send Message</button>

          </form>

          <ul class="contact-list">

            <li class="contact-item">
              <div class="contact-card">

                <div class="card-icon">
                  <ion-icon name="mail-outline" aria-hidden="true"></ion-icon>
                </div>

                <div class="card-content">

                  <h3 class="h3 card-title">Mail Here</h3>

                  <a href="mailto:partner@gwalaxmedia.com" class="card-link">partner@gwalaxmedia.com</a>
                  

                </div>

              </div>
            </li>

            <li class="contact-item">
              <div class="contact-card">

                <div class="card-icon">
                  <ion-icon name="logo-skype" aria-hidden="true"></ion-icon>
                </div>

                <div class="card-content">

                  <h3 class="h3 card-title">Skype Here</h3>

                  <address class="card-address">
                  live:.cid.a2a5b679b36c39e6
                  </address>

                </div>

              </div>
            </li>

            <li class="contact-item">
              <div class="contact-card">

                <div class="card-icon">
                  <ion-icon name="headset-outline" aria-hidden="true"></ion-icon>
                </div>

                <div class="card-content">

                  <h3 class="h3 card-title">Call Here</h3>

                  <a href="tel:+917898620456" class="card-link">+917898620456</a>
                  

                </div>

              </div>
            </li>

          </ul>

        </div>
      </section>
  )
}

export default Contact