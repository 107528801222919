import React from 'react'


const Homemain = () => {
  return (
    
    <section class="section hero" id="home" aria-label="hero">
    <div class="container">

      <div class="hero-content">

        <p class="hero-subtitle">GwalaxMedia Marketing Agency</p>

        <h1 class="h1 hero-title">Welcome To GwalaxMedia Affiliate Network</h1>

        <p class="hero-text">
        GwalaxMedia - Best Affiliate Marketing in India 2024 We work on CPA,CPI,CPR,CPS,CPV Etc Models, GwalaxMedia is India's Affiliate Network and we aim to deliver quality traffic for performance based advertising worldwide in digital advertising industry
        </p>

        <div style={{display:'flex',justifyContent:'space-around',width:'100%'}}>
        <a target='_blank' href="https://gwalaxmedia.offerborn.com/advertiser" class="btn btn-primary">FOR ADVERTISER</a>
        <a target='_blank' href="https://gwalaxmedia.offerborn.com/publisher" class="btn btn-primary">FOR PUBLISHER</a>
        </div>

      </div>

      <figure class="hero-banner">
        <img src="https://res.cloudinary.com/dyueow7yj/image/upload/v1666166851/hero-banner_hwvk4k.png"  width="720" height="673" alt="hero banner" class="w-100"/>
      </figure>

    </div>
  </section>
  )
}

export default Homemain