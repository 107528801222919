import logo from './logo.svg';
import './App.css';
import './globle.css'
import Header from './components/Header';
import {BrowserRouter as Router,Routes,Route, Link} from "react-router-dom";
import Home from './Pages/Home';
import Footer from './components/Footer';
import Privacypoliciy from './Pages/Privacypoliciy';
import Termsandcondition from './Pages/Termsandcondition';
import Aboutus from './Pages/Aboutus';
import Services from './Pages/Services';
import Contact from './Pages/Contact';
import Modelss from './Pages/Modelss';

function App() {
  return (
    <div className="App">
     <Router>
      <Header/>

      <Routes>


      <Route path='/' element={<Home/>}/>
      <Route path='*' element={<Home/>}/>
      <Route path='/privacy-policy' element={<Privacypoliciy/>}/>
      <Route path='/terms-&-conditions' element={<Termsandcondition/>}/>
      <Route path='/about' element={<Aboutus/>}/>
      <Route path='/service' element={<Services/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/workingmodel' element={<Modelss/>}/>
      
      </Routes>
      <Footer/>

     </Router>
    </div>
  );
}

export default App;
