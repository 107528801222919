import React, { useState } from 'react'
// import '../assets/img/gwalaxmedia-logo.png'


const Header = () => {


  const [open,setopen] = useState(false)
console.log(open)

  return (
    <header  className="header" data-header>
    <div className="container">

      <a href="/" className="logo"><img classNameName='logoimg' style={{height:45,width:200}} src='../assets/img/gwalaxmedia-logo.png' /></a>

      

      <nav className={`navbar container  ${open&& 'active'}`}  data-navbar>
        <ul className="navbar-list">

          <li>
            <a href="/" className="navbar-link" data-nav-link>Home</a>
          </li>

          <li>
            <a href="/service" className="navbar-link" data-nav-link>Services</a>
          </li>

          <li>
            <a href="/workingmodel" className="navbar-link" data-nav-link>Working Models</a>
          </li>
{/* 
          <li>
            <a href="#project" className="navbar-link" data-nav-link>About us</a>
          </li> */}

          <li>
            <a href="/about" className="navbar-link" data-nav-link>About Us</a>
          </li>

          {/* <li>
            <a href="#blog" className="navbar-link" data-nav-link>Blog</a>
          </li> */}

          <li>
            <a href="/contact" className="navbar-link" data-nav-link>Contact Us</a>
          </li>

          <li>
            <a href="https://gwalaxmedia.offerborn.com/" className="btn btn-primary">LOGIN</a>
          </li>

        </ul>
      </nav>

      <button onClick={()=>setopen(!open)} className="nav-toggle-btn" aria-label="Toggle menu" data-nav-toggler>
        
        
        {
          open?<ion-icon name="close-outline" className="close"></ion-icon>:<ion-icon name="menu-outline"  className="open"></ion-icon>
        }
      </button>

    </div>
  </header>
  )
}

export default Header