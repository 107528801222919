import React from 'react'
import Cardview from '../components/Cardview'

const Services = () => {
    return (
        <div style={{marginTop:30,backgroundColor:'gray'}}>
            <section class="section service" id="service" aria-label="service">
                <div class="container">

                    <h2 class="h2 section-title">Services We Provided</h2>

                    <p class="section-text">
                    voice 7 Collaboration Services That Will Make You Feel Like You are in the Same Office
                    </p>


                    <div className='grid-list container'>
                        <Cardview img={'laptop-outline'} text={'Web Development'} subtext={'Get a user-friendly website design that creates a strong identity of your brand and meets search engine requirements.'}/>

                        <Cardview img={'phone-portrait-outline'} subtext={'Get customized app solutions for iOS and Android. And User Friendly Basis App Development and we Build Very Fast Your App'} text={'App Development'}/>

                        <Cardview img={'megaphone-outline'} subtext={'With our vast network and experience of running campaigns, you get new affiliates and new source of revenue.'} text={'Affiliate Marketing'}/>

                        <Cardview img={'mail-outline'} subtext={'We make sure that your email marketing campaigns shows quantifiable results and reach target audience effectively.'} text={'Email Marketing'}/>

                        <Cardview img={'clipboard-outline'} subtext={'Our Publishers help our advertisers to increase Installs, Registrations & Traffic for android and IOS apps'} text={'Mobile Marketing'}/>
                        
                        <Cardview img={'hammer-outline'} subtext={'With our SEO services, your brand gets to rank higher than your competitors on search engine results pages,'} text={'SEO Optimization'}/>


                       
                    </div>

                </div>
            </section>
        </div>
    )
}

export default Services